import {Flex, Loader} from "@aws-amplify/ui-react";
import {UpdateOrders} from "./UpdateOrders";
import {GeneratePackingSlips} from "./GeneratePackingSlips";
import {useClientConfiguration} from "../hooks/useClientConfiguration";
import {ProcessNames} from "../api/types";
import {GeneratePickingList} from "./GeneratePickingList";
import {ImportOrders} from "./ImportOrders";
import {GroupShippingLabels} from "./GroupShippingLabels";

export const Dashboard = () => {
  const {data: clientConfiguration, isLoading} = useClientConfiguration()

  if (isLoading) return <Loader size='large' ariaLabel="Loading..."/>

  return <Flex direction="column">
    {clientConfiguration?.processes.includes(ProcessNames.UPDATE_ORDERS) && <UpdateOrders/>}
    {clientConfiguration?.processes.includes(ProcessNames.GENERATE_PACKING_SLIPS) && <GeneratePackingSlips/>}
    {clientConfiguration?.processes.includes(ProcessNames.GENERATE_PICKING_LIST) && <GeneratePickingList/>}
    {clientConfiguration?.processes.includes(ProcessNames.IMPORT_ORDERS) && <ImportOrders/>}
    {clientConfiguration?.processes.includes(ProcessNames.GROUP_SHIPPING_LABELS) && <GroupShippingLabels/>}
  </Flex>
}