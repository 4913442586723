import {ProcessNames, ImportProcessResult, ProcessStatus} from "../api/types";
import {FileUpload} from "./FileUpload";
import {useProcessStatus} from "../hooks/useProcessStatus";
import {Accordion, Card, Message, View} from "@aws-amplify/ui-react";
import {RunProcess} from "./RunProcess";
import {useState} from "react";
import {useApiClient} from "../contexts/ApiClientContext";
import {uploadImportFile} from "../api";
import {columnNumberToLetter} from "../utils";


const formatProcessResult = (processResult: ImportProcessResult) => {
  if (processResult.status === 'success') {
    return processResult.ids?.map((id, index) => <Message colorTheme="success" hasIcon={false}>
      <div><span style={{
        fontWeight: 'bold',
        marginRight: '10px'
      }}>Row {index + 2}</span>
        <span>Success. Id: {id}</span></div>
    </Message>)
  } else {
    return processResult.errors?.map(error => <Message colorTheme="error" hasIcon={true}>
      <div>
        {error.source?.row_index !== undefined && <span style={{
          fontWeight: 'bold',
          marginRight: '10px'
        }}>Row {error.source?.row_index + 2}</span>}
        <span>{error.source?.pointer_source_column !== undefined ? `Error in column ${columnNumberToLetter(error.source?.pointer_source_column + 1)}:` : ''} {error.detail}.</span>
      </div>
    </Message>)
  }
}
export type ImportResultProps = {
  result: ProcessStatus['result']
}
export const ImportResult = ({result}: ImportResultProps) => {
  if (!result) return <></>

  return <Accordion.Container>
    <Accordion.Item value="Accordion-item">
      <Accordion.Trigger>
        <strong>Import result</strong>
        <Accordion.Icon/>
      </Accordion.Trigger>
      <Accordion.Content style={{padding: 10}}>
        {formatProcessResult(result)}
      </Accordion.Content>
    </Accordion.Item>
  </Accordion.Container>
}

const processName = ProcessNames.IMPORT_ORDERS
export const ImportOrders = () => {
  const {data:processStatus, isLoading} = useProcessStatus(processName);
  const [files, setFiles] = useState<File[]>([]);
  const client = useApiClient();

  const preparePayload = async () => {
    if (!client) return Promise.reject('No client')

    const objectKey = await uploadImportFile(client, files[0])
    return {
      file_object_key: objectKey
    };
  }

  return <Card variation="outlined">
    <View textAlign="start">
      <p style={{fontSize: 16, fontWeight: 'bold', marginTop: 0}}>Import orders</p>

      <div style={{marginBottom: 20}}>
        <FileUpload files={files} setFiles={setFiles}/>
      </div>
      <div style={{marginBottom: 20}}>
        <RunProcess processName={processName}
                    preparePayload={preparePayload}
                    resultMessage="Your request to import orders has been successfully initiated. The result of the import will be available within the next 2-3 minutes."
                    isLoading={processStatus?.isRunning}
                    disabled={isLoading || files.length === 0}
                    onSuccess={() => {
                      setFiles([]);
                    }}
        ></RunProcess>
      </div>
      {processStatus?.lastRun &&
          <p>Last run: <span style={{fontStyle: 'italic'}}>{processStatus?.lastRun}</span></p>
      }

      {processStatus?.isRunning === false && <ImportResult result={processStatus?.result}/>}
    </View>
  </Card>
}