import {GeneratedDocuments, GroupLabelsProcessResult, ProcessNames, ProcessStatus} from "../api/types";
import {Accordion, Link, Message} from "@aws-amplify/ui-react";
import {useDocuments} from "../hooks/useDocuments";


const formatProcessResult = (processResult: GroupLabelsProcessResult, documents: GeneratedDocuments[] | undefined) => {
  if (processResult.status === 'success') {
    return <Message colorTheme="success" hasIcon={false}>
      <p style={{
        fontWeight: 'bold',
        marginRight: '12px'
      }}>Success!</p>
      {processResult.cross_warehouse_shipment && processResult.cross_warehouse_shipment.length > 0 &&
          <div><span style={{
            fontWeight: 'bold',
            marginRight: '10px'
          }}>Cross-Warehouse Shipment:</span>
            {processResult.cross_warehouse_shipment.map((shipmentId) =>
              <p style={{margin: 0}}>{shipmentId}</p>)}
          </div>}
      {processResult.unavailable_sku_shipment && processResult.unavailable_sku_shipment.length > 0 &&
          <div><span style={{
            fontWeight: 'bold',
            marginRight: '10px'
          }}>Unavailable SKU Shipment:</span>
            {processResult.unavailable_sku_shipment.map((shipmentId) =>
              <p style={{margin: 0}}>{shipmentId}</p>)}</div>}
      {processResult.result_files && processResult.result_files.length > 0 &&
          <div><p style={{
            fontWeight: 'bold',
            marginRight: '10px',
            marginTop: 0,
            marginBottom: 0
          }}>Result files:</p>
            {processResult.result_files.map((fileName, index) => {
              const document = documents?.find(doc => doc.key === fileName)

              return <Link key={index} href={document?.url} style={{display: 'block'}}>{document?.fileName}</Link>
            })
            }</div>}
    </Message>
  } else {
    return processResult.errors?.map(error => <Message colorTheme="error" hasIcon={true}>
      {error.detail}
    </Message>)
  }
}

export type GroupShippingLabelsProcessResultProps = {
  result: ProcessStatus['result']
}
export const GroupShippingLabelsProcessResult = ({result}: GroupShippingLabelsProcessResultProps) => {
  //TODO Optimise it. Do not fetch all documents
  const {data: documents} = useDocuments(ProcessNames.GROUP_SHIPPING_LABELS, result?.status === 'success');

  if (!result) return <></>

  return <Accordion.Container defaultValue={['Accordion-item']}>
    <Accordion.Item value="Accordion-item">
      <Accordion.Trigger>
        <strong>Process result</strong>
        <Accordion.Icon/>
      </Accordion.Trigger>
      <Accordion.Content style={{padding: 10}}>
        {formatProcessResult(result, documents)}
      </Accordion.Content>
    </Accordion.Item>
  </Accordion.Container>
}

