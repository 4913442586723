import {
  ProcessNames,
} from "../api/types";
import {useProcessStatus} from "../hooks/useProcessStatus";
import {Button, Card, Flex, View} from "@aws-amplify/ui-react";
import {RunProcess} from "./RunProcess";
import {useState} from "react";
import {GroupShippingLabelsSettings} from "./GroupShippingLabelsSettings";
import {GroupShippingLabelsProcessResult} from "./GroupShippingLabelsProcessResult";



const processName = ProcessNames.GROUP_SHIPPING_LABELS
export const GroupShippingLabels = () => {
  const {data: processStatus, isLoading} = useProcessStatus(processName);
  const [showSettings, setShowSettings] = useState(false);

  return <Card variation="outlined">
    <View textAlign="start">
      <Flex justifyContent="space-between">
        <p style={{fontSize: 16, fontWeight: 'bold', marginTop: 0}}>Group shipping labels</p>
        <Button size="small" disabled={processStatus?.isRunning}
                onClick={() => setShowSettings(!showSettings)}>{showSettings ? 'Hide settings' : 'Settings'}</Button>
      </Flex>
      {showSettings ? <GroupShippingLabelsSettings/> : <div>
        <div style={{marginBottom: 20}}>
          <RunProcess processName={processName}
                      resultMessage="Your request to group labels has been successfully initiated. The result of the process will be available within the next 2-3 minutes."
                      isLoading={processStatus?.isRunning}
                      disabled={isLoading}
          ></RunProcess>
        </div>
        {processStatus?.lastRun &&
            <p>Last run: <span style={{fontStyle: 'italic'}}>{processStatus?.lastRun}</span></p>
        }

        {processStatus?.isRunning === false && <GroupShippingLabelsProcessResult result={processStatus?.result}/>}
      </div>}
    </View>
  </Card>
}