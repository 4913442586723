import {useQuery, UseQueryResult} from '@tanstack/react-query'
import {getDocuments} from "../api";
import {useApiClient} from "../contexts/ApiClientContext";
import {GeneratedDocuments, ProcessNames} from "../api/types";
import {AxiosError} from "axios";

export const getGeneratedDocumentsKey = (processName: string) => ['generated_documents', processName]
export const useDocuments = (processName: ProcessNames, enabled: boolean = true): UseQueryResult<GeneratedDocuments[], AxiosError> => {
  const client = useApiClient();

  const queryKey = getGeneratedDocumentsKey(processName)

  return useQuery(
    {
      queryKey,
      queryFn: () => getDocuments(client!, processName),
      enabled: !!client && enabled
    }
  )
}
