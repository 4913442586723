import {useApiClient} from "../contexts/ApiClientContext";
import {useState} from "react";
import {uploadSkuPerWarehouseFile} from "../api";
import {Flex, Link, Message} from "@aws-amplify/ui-react";
import {FileUpload} from "./FileUpload";

export const GroupShippingLabelsSettings = () => {
  const client = useApiClient();
  const [files, setFiles] = useState<File[]>([]);
  const [changeAvailabilityFile, setChangeAvailabilityFile] = useState(false);
  const [error, setError] = useState<string | undefined>()

  const onSave = async () => {
    if (!client) return Promise.reject('No client')

    try {
      setError(undefined)
      await uploadSkuPerWarehouseFile(client, files[0])
    } catch {
      setError('An error occurred while uploading the file. Please try again.')

      setTimeout(() => {
        setError(undefined)
      }, 5000)
    }

    setFiles([])
    setChangeAvailabilityFile(false)
  }

  return <div>
    <Flex>
      <p style={{fontSize: 14, fontWeight: 'bold', margin: 0}}>Availability per warehouse file (.xlsx):</p>
      {/*<Link href="#">Download</Link>*/}
      <Link
        onClick={() => setChangeAvailabilityFile(!changeAvailabilityFile)}>{changeAvailabilityFile ? 'Cancel' : 'Change'}</Link>
    </Flex>
    {changeAvailabilityFile &&
        <div style={{marginTop: 10}}><FileUpload files={files} setFiles={setFiles} onSave={onSave}
                                                 acceptedFileTypes={['.xlsx']}/></div>}
    {error && <Message colorTheme="error" hasIcon={true} style={{marginTop: 10}}>
      {error}
    </Message>}
  </div>
}